@import 'colours';

body {
    font-family: $font;
    font-size: 18px;
    color: $text;
    line-height: 1.2;
}
audio,
canvas,
iframe,
img,
svg,
video {
    vertical-align: middle;
    max-width: 100%;
}
img {
    height:auto !important;
}
h1, h2 {
    font-size: 2em;
}
h3 {
    font-size: 1.3em;
    /* font-weight: 600; */
}
.clear-float {
    clear:both;
}

.light {
    color: $light;
}
.green {
    color: $primary;
}

.button {
    display: inline-block;
    vertical-align: middle;
    text-align: left;
    text-decoration: none;
    font-weight: normal;
    font-size: 18px;
    line-height: 1;
    padding: 10px;
    padding-right: 20px;
    max-width: 100%;
    color: $primary;
    background-color: transparent;
    border: 1.5px solid $formGrey;
    box-sizing: border-box;
    transition: all 0.3s ease;
}
.button:hover {
    color: #FFF;
    background-color: $primary;
}

img.display-left {
    float:left;
    max-width: 50%;
    margin-right:20px;
    margin-bottom:10px;
}

img.display-right {
    float:right;
    max-width: 50%;
    margin-left:20px;
    margin-bottom:10px;
}

.col-wrap {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-evenly;
    box-sizing: border-box;
}
.half {
    display: inline-block;
    vertical-align: top;
    width: 48%;
    box-sizing: border-box;
}

@media only screen and (max-width: 760px){
    img.display-left,
    img.display-right {
        float: none;
        max-width: 100%;
        margin: 20px 0;
    }
    .half {
        width: 100%;
    }
}