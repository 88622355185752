/* styleguide colours */
$primary: #2C903C;
$light: #FAC606;
$coolGrey: #dfe1df;
$darkGrey: #2C4244;
$blacktint: #555;

/** additonal colours **/
$text: #808080;
$tint: #f2f2f2;
$formGrey: #b3b3b3;
$fallback: #c0c0c0;

/* fonts */
$font: 'Fira Sans', sans-serif;